
.townhallText{


}

.addToCalendarDiv{


    padding-left: 10px;
}

.awshclsLogo{
  width: 180px;
}

.townhallNotice{
  display: flex;
  align-items: center;
  height: 42px;
}

.react-add-to-calendar__dropdown{

  z-index: 10;
}

.townhallNotice .react-add-to-calendar{
}


@media only screen and (max-width: 990px) {

  .App .addToCalendarDiv {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 0px;
  }

  .townhallNotice{
    display: inline;
  }

}

@media only screen and (max-width: 768px) {
  .navbar-brand.title{
    font-size: 1.25em !important;
  }


  .App .facets  {
      font-size: .75rem;
  }

  .App .awshclsLogo{
    width: 100px;
  }


  .navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0px;
  }

  .App .row {
    display: flex;
    flex-wrap: nowrap;
    margin-right: -15px;
    margin-left: -15px;
  }

  .App h5{

    font-size: 1rem;
  }

  .App .companyRow{

    display: flow-root;


  }

  .App .whatThisIsCopy.siteDescriptionToggledOff{

    display: none;

  }
  .App .whatThisIsCopy.siteDescriptionToggledOn{
    display: inline;

  }


  .companyIcon img{


    margin-bottom: 10px;

  }

  .App .siteDescriptionToggle{
    font-size: .75rem;
    display: inline;
  }
}


#navbar{

  display: flex;
  align-items: center;
}

#navbar .form-group {
    margin-bottom: 0px;
}

.siteDescriptionToggle{

  display: none;
  padding-left: 10px;
}

.card {
  background: none !important;
  border: none !important;
  padding: none !important;
}

.card-header{
  padding: 2% !important;
  background: none !important;
  border: none !important;
  font-weight: bold;
}

.countryList{
  padding-top: 2% !important;
  padding-left: 3% !important;
  margin-bottom: 3% !important;
}


.navbar{
  padding: 0px !important;
}

.App .companyRow .emailButton  {
  color: white;
  margin-bottom: 5px;

}


.whatThisIsCopy {
  margin-bottom: 15px;
}

.companyDescriptionColumn{

  width: 100%;
}

.container{
  margin-top: 10px;
}


.navbar-brand.title{
  font-size: 2em;
}

.companyRow:nth-child(even){


}

.companyIcon img{

  width: 100px;
  margin-right: 10px;

}

.companyRow{
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid black;
  display: flex;
  align-items: center;

}

.titleStartups{
  border-bottom: 1px solid black;
  padding-bottom: 1%;
}

html, body { font-family: "Amazon Ember", "Helvetica", "sans-serif" !important; margin: 0; }
.App a { color: #FF9900 }
h1 { font-weight: 300 !important; }
